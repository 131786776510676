import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '@/components/Layout'
import ReactMarkdown from 'react-markdown'
import Helmet from 'react-helmet'
import SEO from '@/helpers/SEOHelper.js'

export const ContactPageTemplate = ({ data }) => {
  const pageData = data.markdownRemark.frontmatter
  const contactData = data.contact.edges[0].node.frontmatter
  const seo = pageData.seo
  return (
    <>
      <Helmet>
        <meta name='description' content={SEO.description(seo.description)} />
        <meta property='og:title' content={SEO.title(seo.title)} />
        <meta
          property='og:description'
          content={SEO.description(seo.description)}
        />
        <meta property='og:image' content={SEO.image(seo.image)} />
        <title>{SEO.title(seo.title)}</title>
      </Helmet>

      <div className='min-h-screen'>
        <section className='pt-48'>
          <div className='container'>
            <div className='flex justify-center'>
              <ReactMarkdown
                source={pageData.hero}
                className='markdown-content text-center max-w-3xl'
              />
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

const ContactPage = ({ data }) => {
  return (
    <Layout hideNav={true}>
      <ContactPageTemplate data={data} />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { template: { eq: "contact" } }) {
      frontmatter {
        title
        hero
        seo {
          description
          title
        }
      }
    }
    contact: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "contact" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            hq
            person
          }
        }
      }
    }
  }
`

export default ContactPage
